.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 85px;
  flex: 1;
}

.pageContent {
  display: flex;
  flex-direction: column;
  height: 30%;
  width: 50%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.emailInput {
  width: 100%;
}
