.pageContainer {
  display: flex;
  flex: 1;
  width: calc(100% - 8vw);
  flex-direction: column;
  text-align: start;
  box-sizing: border-box;
  align-items: center;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 85px;
}

.mobilePageContainer {
  display: flex;
  flex: 1;
  width: calc(100% - 8vw);
  flex-direction: column;
  text-align: start;
  box-sizing: border-box;
  align-items: center;
  margin-left: 4vw;
  margin-right: 4vw;
  margin-top: 25px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;
}

/* GalleryCarousel Styles */

.galleryCarousel {
  display: flex;
}

/* InPageCarousel Styles */

.carouselContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
}

.carouselTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25%;
  margin-bottom: 2rem;
}

.carouselContentContainer {
  display: flex;
  height: 60vh;
  gap: 1rem;
}

.mobileCarouselContentContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.carouselImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.carouselImage {
  width: 100%;
  height: 85%;
  object-fit: contain;
  cursor: zoom-in;
}

.carouselImageInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 15%;
}

.carouselThumbnailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.carouselThumbnailContent {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 80%;
  overflow-y: scroll;
  justify-content: start;
  flex-wrap: wrap;
  gap: 1rem;
}

.carouselThumbnailSpacer {
  width: 100%;
  height: 2px;
}

.carouselThumbnail {
  display: flex;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.carouselThumbnailImage {
  width: 100%;
  height: auto;
  object-fit: contain;
  margin: 5px;
}

.carouselThumbnailScrollButton {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

/* ModalCarousel Styles */

.carouselOverlay {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 90%);
  z-index: 1300;
  gap: 1rem;
  padding: 2rem;
  animation: fadeIn 0.5s ease-in-out;
}

.carouselOverlayClosing {
  animation: fadeOut 0.5s ease-in-out;
}

.carouselOverlayCloseButton {
  top: 32px;
  left: 32px;
  color: white;
  z-index: 1301;
  background-color: rgb(0 0 0 / 30%);

  &:hover {
    background-color: rgb(0 0 0 / 50%);
  }
}

.carouselOverlayNavLeft {
  top: 50%;
  left: 32px;
  transform: translateY(-50%);
  z-index: 1301;
  color: white;
  background-color: rgb(0 0 0 / 30%);

  &:hover {
    background-color: rgb(0 0 0 / 50%);
  }
}

.carouselOverlayNavRight {
  top: 50%;
  right: 32px;
  transform: translateY(-50%);
  z-index: 1301;
  color: white;
  background-color: rgb(0 0 0 / 30%);

  &:hover {
    background-color: rgb(0 0 0 / 50%);
  }
}

.carouselOverlayContainer {
  position: relative;
  width: auto;
  height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
}

.carouselOverlayTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25%;
}

.carouselOverlayImageInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25%;
}

.carouselOverlayContentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.5s ease-in-out;
}

.carouselOverlayContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  backface-visibility: hidden;
  transition: opacity 0.25s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
