.pageContainer {
  display: flex;
  width: calc(100% - 12vw);
  flex-direction: column;
  text-align: start;
  box-sizing: border-box;
  align-items: center;
  margin-left: 6vw;
  margin-right: 6vw;
  margin-top: 85px;
}

.mobilePageContainer {
  display: flex;
  width: calc(100% - 12vw);
  flex-direction: column;
  text-align: start;
  box-sizing: border-box;
  align-items: center;
  margin-top: 25px;
  margin-left: 6vw;
  margin-right: 6vw;
}

/* BlogList Styles */

.blogListContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 50%;
  transition: opacity 0.15s ease-in;
}

.blogListPostsContainer {
  display: grid;
  box-sizing: border-box;
  gap: 3rem;
  width: 100%;
}

.blogListHeaderMain {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.blogListHeaderButton {
  display: flex;
  align-items: center;
  text-transform: none;
  width: 15%;
}

.blogListHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.blogListFin {
  margin-top: 2rem;
}

/* BlogPost Styles */

.blogListPost {
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 350px;
  transition: opacity 0.2s ease-in;
}

.mobileImageArrow {
  padding: 1rem;
  cursor: pointer;
}

.mobileImageArrowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  aspect-ratio: 1 / 1;
  transition: opacity 0.15s ease-in-out;
}

.listPostImage {
  width: 100%;
  min-height: 100%;
  height: 100%;
  aspect-ratio: 1;
  object-fit: contain;
  margin-bottom: 1rem;
  user-select: none;
}

.listPostImage:hover {
  opacity: 0.85;
}

.featuredPostImage {
  transition: opacity 0.15s ease-in-out;
}

.featuredPostImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.featuredPost {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
}

.postInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: opacity 0.15s ease-in-out;
  min-height: 12rem;
  gap: 2;
}

.postDivider {
  width: 50px;
  height: 1px;
  background-color: #ce0000;
  margin-bottom: 0.5rem;
}

.postTitleContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.postDate {
  color: #ce0000;
  margin-right: 1rem;
  font-weight: 600;
}

.postTags {
  font-style: italic;
  margin-top: 0.5rem;
  margin-bottom: 0.75rem;
}

.postTag {
  font-style: italic;
  cursor: pointer;
  border: 1px solid grey;
  width: fit-content;
  padding: 0.2rem 0.35rem;
  border-radius: 15px;
}

.postTag:hover {
  color: #ce0000;
  border: 1px solid #ce0000;
}

/* FeaturedPost Styles */

.featuredContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  align-items: center;
  margin-bottom: 2rem;
  transition: opacity 0.15s ease-in-out;
}

.breadcrumbContainer {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.breadcrumb {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
