.pageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 1vw;
}

.pageContent {
  display: flex;
  flex-direction: column;
}

.pageSubtitleContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.buttonContainer {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2vw;
}
