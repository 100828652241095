.footerContainer {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 75px;
  border-top: 1px solid rgb(135 135 135 / 10%);
  margin-top: 5vh;
}

.footerContainerMobile {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: auto;
  min-height: 75px;
  border-top: 1px solid rgb(135 135 135 / 10%);
  margin-top: 5vh;
  padding-bottom: 85px;
}

.footerLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  width: 60%;
  height: 100%;
  padding: 10px;
}

.footerLink {
  text-align: center;
  margin-left: 20px;
  color: #231f20;
  cursor: pointer;
}

.footerRight {
  width: 90%;
  text-align: center;
  color: grey;
}

.footerRightText {
  cursor: pointer;
}
