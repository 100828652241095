.navigationLeft {
  position: fixed;
  display: flex;
  flex-direction: column;
  left: 2vw;
  height: 100%;
  width: 11vw;
  align-items: center;
  justify-content: center;
}

.navigationRight {
  position: fixed;
  display: flex;
  flex-direction: column;
  right: 2vw;
  height: 100%;
  width: 11vw;
  align-items: center;
  justify-content: center;
}

.navigationObject {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards 0.5;
}

.navigationObject:hover {
  opacity: 1 !important;
}

.pageContainer {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  text-align: start;
  box-sizing: border-box;
  align-items: start;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 85px;
}

.mobilePageContainer {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  text-align: start;
  box-sizing: border-box;
  align-items: start;
  margin-left: 15vw;
  margin-right: 15vw;
  margin-top: 25px;
}

.title {
  font-weight: 600;
  margin-bottom: 1rem;
}

.paragraph {
  margin-bottom: 1rem;
  width: 100%;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.image {
  width: 50%;
}

.postBookend {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
  width: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
