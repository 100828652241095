code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  font-family: Inter, sans-serif;
  font-feature-settings:
    'liga' 1,
    'calt' 1;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: InterVariable, sans-serif;
  }
}

html,
body {
  height: 100%;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
