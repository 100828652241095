.themeToggle {
  justify-content: center;
  align-items: center;
}

.themeToggleIconButton {
  position: relative;
  transition: transform 0.75s ease-in-out;
}

.themeButtonIcon {
  position: absolute;
  transition: opacity 0.25s ease-in-out;
}
