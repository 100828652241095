.pageContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: start;
  align-items: start;
  height: 100%;
  width: calc(100% - 8vw);
  margin-top: 85px;
  margin-left: 4vw;
  margin-right: 4vw;
}

.pageTitleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.pageContentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.accordion {
  width: 100%;
}
