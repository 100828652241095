.navbarDesktopContainer {
  position: fixed;
  flex-shrink: 0;
  width: 100%;
  height: 85px;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.navbarMobileContainer {
  position: fixed;
  flex-shrink: 0;
  width: 100%;
  height: 85px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}

.navbarInner {
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 15px 4vw;
  width: 100%;
  height: 100%;
}

.navbarLeft {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 25%;
  height: 100%;
}

.navbarCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  min-width: 75%;
}

.navbarTitleLeft {
  cursor: pointer;
}

.navbarTitleRight {
  cursor: pointer;
  padding-left: 0.5rem;
}

.navbarRight {
  display: flex;
  justify-content: right;
  box-sizing: border-box;
  align-items: center;
  width: 25%;
  height: 100%;
}

/* SidebarOverlay Styles */

.overlay {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);
  z-index: 3;
  animation: fadeIn 0.5s forwards;
}

.closingOverlay {
  animation: fadeOut 0.5s forwards;
}

.dropdownMenu {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  z-index: 3;
  padding: 20px;
  animation: slideIn 0.5s forwards;
}

.dropdownMenuMobile {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 85px);
  bottom: 85px;
  z-index: 3;
  padding: 20px;
  animation: fadeIn 0.5s forwards;
}

.closingDropdown {
  animation: slideOut 0.5s forwards;
}

.closingDropdownMobile {
  animation: fadeOut 0.5s forwards;
}

.navbarSidebarTitle {
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
}

.navbarItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarText {
  padding: 0 5px;
}

.navbarText:hover {
  color: #be4fff;
  cursor: pointer;
}

.navbarText.navbarTextActive {
  color: #be4fff;
  cursor: default;
}

.navbarIconButtons {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: end;
  gap: 2rem;
  margin-top: 2rem;
}

.navbarIconButton:hover {
  color: #be4fff;
  cursor: pointer;
}

/* SearchOverlay Styles */

.searchOverlayDesktop {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 85px;
  z-index: 2;
  animation: fadeIn 0.5s forwards;
}

.searchOverlayMobile {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  bottom: 85px;
  z-index: 2;
  animation: fadeIn 0.5s forwards;
}

.closingSearchOverlay {
  animation: fadeOut 0.5s forwards;
}

.searchContainerDesktop {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw;
  top: 85px;
  width: 100%;
  height: 100%;
  z-index: 2;
  animation: fadeIn 0.5s forwards;
}

.searchContainerMobile {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5vw;
  bottom: 85px;
  width: 100%;
  height: calc(100% - 85px);
  z-index: 2;
  padding-top: 100px;
  animation: fadeIn 0.5s forwards;
}

.searchBar {
  min-width: 25%;
  animation: fadeIn 0.5s forwards;
}

.searchResults {
  display: flex;
  flex-direction: column;
  padding: 20px 5vw 5vw;
  top: 85px;
  width: 100%;
  height: 100%;
  z-index: 2;
  overflow-y: auto;
}

.searchResultsHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.searchResult {
  margin-bottom: 20px;
}

.searchPending {
  align-items: center;
  margin-top: 20vh;
}

@keyframes slideIn {
  from {
    left: -50%;
  }

  to {
    left: 0;
  }
}

@keyframes slideOut {
  from {
    left: 0;
  }

  to {
    left: -50%;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
