.pageContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  text-align: start;
  align-items: start;
  height: 100%;
  width: 100%;
  margin: 1vw;
}

.pageErrorCode {
  height: 30%;
}
