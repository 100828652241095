.pageContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 100%;
  width: calc(100% - 10vw);
  margin-top: 100px;
  margin-right: 5vw;
  margin-left: 5vw;
  align-items: center;
  justify-content: center;
}

.mobilePageContainer {
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  height: 100%;
  width: calc(100% - 10vw);
  margin-top: 25px;
  margin-right: 5vw;
  margin-left: 5vw;
  align-items: center;
  justify-content: center;
}

.pageContent {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}

.imageContainer {
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pageImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50px;
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.icon {
  animation: pulseLight 1.5s infinite;
  cursor: pointer;
}

.iconDark {
  animation: pulseDark 1.5s infinite;
  cursor: pointer;
}

@keyframes pulseDark {
  0% {
    color: #000;
  }

  50% {
    color: #b12affff;
  }

  100% {
    color: #000;
  }
}

@keyframes pulseDark {
  0% {
    color: #fff;
  }

  50% {
    color: #b12affff;
  }

  100% {
    color: #fff;
  }
}
